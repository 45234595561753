import { SingleWine } from "../WineListPage/WineListPage";
import styled from 'styled-components';
import { useCallback, useEffect, useState } from "react";
import breakpoints from "../../../breakpoints";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";

const FlexBox = styled.div<{selected?: number | null}>`
    display: grid;
    grid-template: 1fr / repeat(1, 1fr);
    white-space: pre-wrap;
    gap: 20px 10px;
    width: 100%;

    ${props => props.selected &&
        `
        > * {
            &:first-child {
                grid-column: 1 / -1;
            }
        }
        `
    }

    @media only screen and (min-width: ${breakpoints.md}) {
        grid-template: 1fr / repeat(2, 1fr);
        gap: 10px;
    }

    @media only screen and (min-width: ${breakpoints.xxxl}) {
        grid-template: 1fr / repeat(3, 1fr);
    }
`;

const setBackgroundColor = (type: string) => {
    switch (type) {
      case 'quet':
        return '#d8dee9';
      case 'trisunca':
        return '#ffffff';
      default:
        return '#e6e6e6';
    }
  };

const WineDisplay = styled.div<{type: string, selected: number | null, currentlySelected: number | null | undefined}>`
    background-color: ${props => setBackgroundColor(props.type)};
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    align-content: center;
    padding: 20px 5px;

    img {
        height: 30rem;
        width: ${props => props.type === 'trisunca' ? '190px' : 'auto'};

        @media only screen and (max-width: ${breakpoints.xxxsm}) {
            height: 24rem;
            width: ${props => props.type === 'trisunca' ? '150px' : 'auto'};
        }

    }

    ${props => props.selected === props.currentlySelected && `
        flex-direction: column;
    `}

    @media only screen and (min-width: ${breakpoints.md}) {
        &:hover {
            outline: 2px solid #D4AF37;
        }
    }

    @media only screen and (min-width: ${breakpoints.xxl}) {
        ${props => props.selected === props.currentlySelected && `
            flex-direction: row;
        `}
    }

    @media only screen and (min-width: ${breakpoints.md}) {
        padding: 20px;
    }
`;

const InnerFlexBox = styled.div<{type: string, selected: number | null}>`
    display: flex;
    flex-direction: column;
    color: ${props => props.type === 'quet' ? 'black' : 'black'};

    ${props => props.selected && `
        width: 100%;
        align-items: center;
        justify-content: center;
    `}
`;

const Title = styled.div<{selected: number | null, currentlySelected: number | null | undefined}>`
    font-size: 26px;
    font-weight: 400;
    overflow-wrap: break-word;
    text-align: center;
    padding-top: 10px;

    @media only screen and (min-width: ${breakpoints.md}) {
        display: block;
        width: 200px;
        padding-top: 0;
        ${props => props.selected === props.currentlySelected && `
            font-size: 50px;
            width: auto;
        `}
    }

    @media only screen and (max-width: ${breakpoints.xxxsm}) {
        font-size: 22px;
    }
`;

const Description = styled.p`
    overflow-wrap: break-word;
    max-width: 50rem;
    font-size: 13px;
    line-height: 20px;
    padding: 25px;
`;

const LiterInfo = styled.p`
    font-size: 13px;
    line-height: 20px;
`;

const SubTitle = styled.div`
    font-size: 15px;
    margin: 10px 25px 0 25px;
    text-align: center;
    line-height: 20px;
`

type WineListProps = {
    wines: SingleWine[];
    type: 'quet' | 'fruskac' | 'trisunca';
}
const WineList = ({wines, type}: WineListProps) => {
    const intl = useIntl();
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const canonicalUrl = `https://fruvin.com${location.pathname}`;
    const [selected, setSelected] = useState<SingleWine | null>();
    const [winesArray, setWinesArray] = useState<SingleWine[]>(wines);

    const getBrandMetaTitle = () => {
        if(type === 'quet') {
            return (
                intl.formatMessage({
                id: 'navigation__quet',
                defaultMessage: 'Quet',
            }))
        } else if(type === 'trisunca') {
            return (
                intl.formatMessage({
                id: 'navigation__trisunca',
                defaultMessage: 'Tri Sunca',
            }))
        } else {
            return (
                intl.formatMessage({
                id: 'navigation__fruskac',
                defaultMessage: 'Fruškać',
            }))
        }
    }

    const handleUrlChange = (wine?: SingleWine) => {
        if (wine) {
            navigate('/' + type + "/" + wine.urlName);
        } else {
            navigate('/' + type);
        }
    }

    const handleWineArrayChange = useCallback((wineId: number) => {
        const oldArray = winesArray;
        const selectedWineIndex = oldArray.findIndex(foundWine => foundWine.id === wineId);
        const selectedWine = oldArray.find(foundWine => foundWine.id === wineId);
        winesArray.splice(selectedWineIndex, 1);
        oldArray.unshift(selectedWine!);
        setWinesArray(oldArray);
    },[winesArray]);

    const scrollToSelectedWine = () => {
        //scroll to element above to see full expanded element
        const element = document.getElementById('description-text');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClick = (wine: SingleWine) => {
        if(selected?.id === wine.id){
            setSelected(undefined);
            handleUrlChange(undefined);
            return;
        }
        setSelected(wine);
        handleUrlChange(wine);
        handleWineArrayChange(wine.id);
    }

    useEffect(() => {
        if(id) {
            const paramWine = winesArray.find(foundWine => foundWine.urlName === id);
            if(paramWine) {
                setSelected(paramWine);
                handleWineArrayChange(paramWine.id)
            }
        }
    }, [id, winesArray, handleWineArrayChange]);

    useEffect(() => {
        if(selected){
            scrollToSelectedWine();
        }
    },[selected])

    return (
        <>
            {selected &&
                <Helmet>
                    <title>
                        {getBrandMetaTitle()} {' '}
                        {intl.formatMessage({
                            id: selected.metaTitleId,
                            defaultMessage: 'Missing translation',
                        })} | Fruskogorski vinogradi
                    </title>
                    <meta
                        name="description"
                        content={
                            intl.formatMessage({
                            id: selected.metaDescriptionId,
                            defaultMessage: 'Missing translation',
                        })}
                    />
                    <link rel="canonical" href={canonicalUrl} />
                </Helmet>
            }
        <FlexBox selected={selected?.id}>
            {winesArray.map((item, i) => {
                return (
                    <WineDisplay key={i} type={type} onClick={() => handleClick(item)} selected={item.id} currentlySelected={selected?.id}>
                        <img src={item.image} alt='wine' />
                        <InnerFlexBox type={type} selected={item.id}>
                            <Title selected={item.id} currentlySelected={selected?.id}>{item.name}</Title>
                            {item.id === selected?.id && <SubTitle>{item.subtitle}</SubTitle>}
                            {item.id === selected?.id && <Description>{item.description}</Description>}
                            {item.id === selected?.id && <LiterInfo>{item.liter}</LiterInfo>}
                        </InnerFlexBox>
                    </WineDisplay>
                )}
            )}
        </FlexBox>
        </>
    );
}

export default WineList;