import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import breakpoints from "../../../breakpoints";
import { useIntl } from "react-intl";

const Wrapper = styled.div`
    background-color: #252121;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 20px;

    @media only screen and (min-width: ${breakpoints.xxsm}) {
        padding: 3rem 5%;
    }

    @media only screen and (min-width: ${breakpoints.md}) {
        flex-direction: row;
        height: 15rem;
    }
`;

const Info = styled.div`
    color: white;
    font-size: 13px;
    line-height: 24px;

    div {
        font-size: 15px;
        padding-bottom: 10px;
        font-weight: bold;
        text-transform: uppercase;
    }

    a {
        color: white;
    }
`;

const QuickLinks = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    gap: 0.5rem;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;

    div {
        font-size: 15px;
        padding-bottom: 10px;
        font-weight: bold;
    }

    span {
        cursor: pointer;

        @media only screen and (max-width: ${breakpoints.md}) {
            line-height: 36px;
        }
    }

    @media only screen and (min-width: ${breakpoints.md}) {
        margin: 0;
    }
`;

const Social = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 13px;
    line-height: 20px;
    color: white;
    margin-bottom: 20px;
    text-transform: uppercase;

    div {
        font-size: 15px;
        padding-bottom: 10px;
        font-weight: bold;
    }

    a {
        color: white;
        text-decoration: none;

        @media only screen and (max-width: ${breakpoints.md}) {
            line-height: 36px;
        }
    }
`;

const Footer = () => {
    const navigate = useNavigate();
    const intl = useIntl();

    const handleClick = (route: string) => {
        navigate(route);
        window.scrollTo(0, 0);
    }

    return (
        <Wrapper>
            <Social>
                <div>{intl.formatMessage({
                        id: "footer__social",
                        defaultMessage: "Missing translation",
                    })}</div>
                <a href="https://www.instagram.com/fruvin_fruskogorskivinogradi/" target="_blank" rel="noreferrer">
                    {intl.formatMessage({
                        id: "footer__instagram",
                        defaultMessage: "Missing translation",
                    })}
                </a>
                <a href="https://www.facebook.com/fruvin" target="_blank" rel="noreferrer">
                    {intl.formatMessage({
                        id: "footer__facebook",
                        defaultMessage: "Missing translation",
                    })}
                </a>
            </Social>

            <QuickLinks>
                <div>
                    {intl.formatMessage({
                        id: "footer__links",
                        defaultMessage: "Missing translation",
                    })}
                </div>
                <span onClick={() => handleClick('trisunca')}>
                    {intl.formatMessage({
                        id: "navigation__trisunca",
                        defaultMessage: "Missing translation",
                    })}
                </span>
                <span onClick={() => handleClick('quet')}>
                    {intl.formatMessage({
                        id: "navigation__quet",
                        defaultMessage: "Missing translation",
                    })}
                </span>
                <span onClick={() => handleClick('fruskac')}>
                    {intl.formatMessage({
                        id: "navigation__fruskac",
                        defaultMessage: "Missing translation",
                    })}
                </span>
                <span  onClick={() => handleClick('contact')}>
                    {intl.formatMessage({
                        id: "navigation__contact",
                        defaultMessage: "Missing translation",
                    })}
                </span>
                <span onClick={() => handleClick('about')}>
                    {intl.formatMessage({
                        id: "navigation__about",
                        defaultMessage: "Missing translation",
                    })}
                </span>
            </QuickLinks>
            <Info>
                <div>
                    {intl.formatMessage({
                        id: "footer__contact_us",
                        defaultMessage: "Missing translation",
                    })}
                </div>
                FRUŠKOGORSKI VINOGRADI D.O.O.
                <br/>
                Miroslava Antića 4, Novi Sad, Srbija
                <br/>
                Dunavska 5, Banoštor, Srbija
                <br/>
                <span>
                    {intl.formatMessage({
                        id: "footer__number",
                        defaultMessage: "Missing translation",
                    })}
                    <a href="tel:+381216613500">+381 (0) 21/6613-500</a>
                </span>
                <br/>
                <span>
                    {intl.formatMessage({
                        id: "footer__number",
                        defaultMessage: "Missing translation",
                    })}
                    <a href="tel:+381648890023">+381 (0) 64/889-0023</a>
                </span>
                <br/>
                <span>
                    {intl.formatMessage({
                        id: "footer__email",
                        defaultMessage: "Missing translation",
                    })}
                    <a href="mailto:info@fruvin.com">info@fruvin.com</a>
                </span>
            </Info>
        </Wrapper>
    );
}

export default Footer;
