import { useEffect } from 'react';
import Image from '../components/atoms/Image/Image';
import MainVideo from '../components/atoms/MainVideo/MainVideo';
import FirstImage from '../assets/fruvin-main-image-first.jpeg';
import SecondImage from '../assets/fruvin-main-image-second.jpeg';
import ThirdImage from '../assets/fruvin-main-image-third.jpeg';
import FourthImage from '../assets/fruvin-main-image-fourth.webp';
import { useIntl } from 'react-intl';
import breakpoints from '../breakpoints';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';

const Wrapper = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 3px;

    @media only screen and (min-width: ${breakpoints.xxsm}) {
        gap: 10px;
    }
`;

const Home = () => {
    const intl = useIntl();

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    return (
        <Wrapper>
            <Helmet>
                <title>Fruškogorski vinogradi - Vrhunska vina sa Fruške gore</title>
                <meta
                    name="description"
                    content="Vinarija Fruškogorski vinogradi, jedna od najprestižnijih vinarija na Fruškoj gori, proizvodi nagrađivane serije vina poput Quet, Fruškać i Tri Sunca. Uživajte u degustaciji vrhunskih vina sa Fruške gore i otkrijte bogate ukuse jedne od najboljih vinskih regija u Srbiji."
                />
                <link rel="canonical" href="https://fruvin.com/" />
            </Helmet>
            <MainVideo />
            <Image image={FirstImage} text={intl.formatMessage({ id: 'home__quet', defaultMessage: 'Missing translation' })} navigateTo={'quet'} textColor={'black'}/>
            <Image image={SecondImage} text={intl.formatMessage({ id: 'home__fruskac', defaultMessage: 'Missing translation' })} navigateTo={'fruskac'} textColor={'white'} loading={'lazy'} />
            <Image image={ThirdImage} text={intl.formatMessage({ id: 'home__fruskogorske_vinograde', defaultMessage: 'Missing translation' })} navigateTo={'about'} textColor={'white'} loading={'lazy'} />
            <Image image={FourthImage} text={intl.formatMessage({ id: 'home__trisunca', defaultMessage: 'Missing translation' })} navigateTo={'trisunca'} textColor={'white'} loading={'lazy'} />
        </Wrapper>
    );
}

export default Home;
