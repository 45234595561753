import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Quet from './pages/Quet';
import './App.css';
import Fruskac from './pages/Fruskac';
import About from './pages/About';
import Contact from './pages/Contact';
import Map from './pages/Map';
import Fallback from './pages/Fallback';
import TermsAndConditions from './pages/TermsAndConditions';
import AppImages from './pages/AppImages';
import "@fontsource/raleway";
import TriSunca from './pages/TriSunca';
import { HelmetProvider } from 'react-helmet-async';

/** @todo novosti komponenta */
function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/trisunca/:id?" element={<TriSunca />} />
        <Route path="/quet/:id?" element={<Quet />} />
        <Route path="/fruskac/:id?" element={<Fruskac />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/map" element={<Map />} />
        <Route path="/tc-reservations" element={<TermsAndConditions />} />
        {/* <Route path="/blogs" element={<Blogs />} /> */}
        <Route path="/appimagespreview" element={<AppImages />} />
        <Route path="*" element={<Fallback />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;
