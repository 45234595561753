import WineListPage from "../components/molecules/WineListPage/WineListPage";
import BackgroundImage from '../assets/fruvin-quet-background.jpeg';
import QuetLogo from '../assets/fruvin-quet.png';
import Sauvignon from '../assets/quet-suvignon.webp';
import PinotBlanc from '../assets/quet-pinot-blanc.webp';
import Grasac from '../assets/quet-grasac.webp';
import Merlot18 from '../assets/quet-merlot-18.webp';
import PinotNoirRose from '../assets/quet-rose-pinot.webp';
import Traminac from '../assets/quet-traminac.webp';
import PinotNoir from '../assets/quet-pinot-noir.webp';
import Chardonay from '../assets/quet-chardonnay.webp';
import ProbusRose from '../assets/quet-probus-rose.webp';
import Quvee from '../assets/quet-quvee.webp';
import QuveeLimited from '../assets/quet-quvee-limited.webp';
import QuveeBlanc from '../assets/quet-quvee-blanc.webp';
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";

const Quet = () => {
    const intl = useIntl();
    const location = useLocation();
    const canonicalUrl = `https://fruvin.com${location.pathname}`;

    useEffect(() => {
        window.scrollTo(0, 0);
    },[]);

    const wines = [
        {
            id: 3,
            image: Grasac,
            name: <FormattedMessage id='quet__grasac_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__grasac_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__grasac_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-grasac',
            subtitle: <FormattedMessage id='quet__grasac_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__grasac_name',
            metaDescriptionId: 'quet__grasac_meta_description'
        },
        {
            id: 2,
            image: PinotBlanc,
            name: <FormattedMessage id='quet__pinot_blanc_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__pinot_blanc_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__pinot_blanc_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-pinot-blanc',
            subtitle: <FormattedMessage id='quet__pinot_blanc_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__pinot_blanc_name',
            metaDescriptionId: 'quet__pinot_blanc_meta_description'
        },
        {
            id: 4,
            image: Merlot18,
            name: <FormattedMessage id='quet__merlot18_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__merlot18_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__merlot18_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-merlot-18-edition',
            subtitle: <FormattedMessage id='quet__merlot18_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__merlot18_name',
            metaDescriptionId: 'quet__merlot18_meta_description'
        },
        {
            id: 6,
            image: Traminac,
            name: <FormattedMessage id='quet__traminac_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__traminac_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__traminac_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-traminac',
            subtitle: <FormattedMessage id='quet__traminac_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__traminac_name',
            metaDescriptionId: 'quet__traminac_meta_description'
        },
        {
            id: 11,
            image: ProbusRose,
            name: <FormattedMessage id='quet__probus_rose_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__probus_rose_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__probus_rose_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-probus-rose',
            subtitle: <FormattedMessage id='quet__probus_rose_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__probus_rose_name',
            metaDescriptionId: 'quet__probus_rose_meta_description'
        },
        {
            id: 8,
            image: Chardonay,
            name: <FormattedMessage id='quet__chardonnay_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__chardonnay_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__chardonnay_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-chardonnay',
            subtitle: <FormattedMessage id='quet__chardonnay_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__chardonnay_name',
            metaDescriptionId: 'quet__chardonnay_meta_description'
        },
        {
            id: 9,
            image: Quvee,
            name: <FormattedMessage id='quet__quvee_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__quvee_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__quvee_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-quvee',
            subtitle: <FormattedMessage id='quet__quvee_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__quvee_name',
            metaDescriptionId: 'quet__quvee_meta_description'
        },
        {
            id: 10,
            image: QuveeLimited,
            name: <FormattedMessage id='quet__quvee_limited_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__quvee_limited_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__quvee_limited_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-quvee-limited',
            subtitle: <FormattedMessage id='quet__quvee_limited_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__quvee_limited_name',
            metaDescriptionId: 'quet__quvee_limited_meta_description'
        },
        {
            id: 7,
            image: PinotNoir,
            name: <FormattedMessage id='quet__pinot_noir_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__pinot_noir_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__pinot_noir_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-pinot-noir',
            subtitle: <FormattedMessage id='quet__pinot_noir_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__pinot_noir_name',
            metaDescriptionId: 'quet__pinot_noir_meta_description'
        },
        {
            id: 12,
            image: QuveeBlanc,
            name: <FormattedMessage id='quet__quvee_blanc_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__quvee_blanc_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__quvee_blanc_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-quvee-blanc',
            subtitle: <FormattedMessage id='quet__quvee_blanc_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__quvee_blanc_name',
            metaDescriptionId: 'quet__quvee_blanc_meta_description'
        },
        {
            id: 5,
            image: PinotNoirRose,
            name: <FormattedMessage id='quet__pinot_noir_rose_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__pinot_noir_rose_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__pinot_noir_rose_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-pinot-noir-rose',
            subtitle: <FormattedMessage id='quet__pinot_noir_rose_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__pinot_noir_rose_name',
            metaDescriptionId: 'quet__pinot_noir_rose_meta_description'
        },
        {
            id: 1,
            image: Sauvignon,
            name: <FormattedMessage id='quet__sauvignon_name' defaultMessage="Missing translation value" />,
            description: <FormattedMessage id='quet__sauvignon_description' defaultMessage="Missing translation value" />,
            liter: <FormattedMessage id='quet__sauvignon_liter' defaultMessage="Missing translation value" />,
            urlName: 'quet-souvignon',
            subtitle: <FormattedMessage id='quet__sauvignon_subtitle' defaultMessage="Missing translation value" />,
            metaTitleId: 'quet__sauvignon_name',
            metaDescriptionId: 'quet__sauvignon_meta_description'
        }
    ];

    return (
        <>
            <Helmet>
                <title>
                    {intl.formatMessage({
                        id: 'navigation__quet',
                        defaultMessage: 'Quet',
                    })} | Fruskogorski vinogradi
                </title>
                <meta name="description" content={
                        intl.formatMessage({
                        id: 'quet__meta_description',
                        defaultMessage: 'Otkrijte vrhunska i nagradjivana vina iz serije Quet vinarije Fruškogorski vinogradi. Uživajte u bogatstvu vina sa Fruške gore.',
                    })} />
                <link rel="canonical" href={canonicalUrl} />
            </Helmet>
            <WineListPage
                type={'quet'}
                backgroundImage={BackgroundImage}
                descriptionText={intl.formatMessage({ id: 'quet__description', defaultMessage: 'Missing translation' })}
                wineLogo={QuetLogo}
                wines={wines}
            />
        </>
    );
}

export default Quet;