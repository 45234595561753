import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Fallback = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const paramString = JSON.stringify(params);

        if(paramString.includes('sr/')) {
            const splittedStringByTwoDots = paramString.split(':')[1];
            const removedBrackets = splittedStringByTwoDots.replaceAll('}', '');
            const removedQuets = removedBrackets.replaceAll('"', '');
            const clearedPrefix = removedQuets.replaceAll('sr/','');
            navigate(clearedPrefix);
        } else if(paramString.includes('quet-wine')) {
            // e.g. http://fruvin.com/quet-wine/quet-pinot-blanc.php
            const splittedByQuetWine = paramString.split('quet-wine/');
            const quetWineName = splittedByQuetWine[1].split('.php')[0];
            navigate('/quet/' + quetWineName);
        } else {
            navigate('/contact');
        }
    }, [params, navigate]);

    return (
        <div>404 NOT FOUND</div>
    );
}

export default Fallback;