import styled from "styled-components";
import breakpoints from '../../../breakpoints';
import WineList from '../WineList/WineList';

const BackgroundImage = styled.div<{image: string, type: string}>`
    width: 100%;

    ${(props) =>
        props.type === 'quet' ?
        `
          background-color: white;
        `
    :
        `
            background: linear-gradient(rgba(255,255,255,.3), rgba(255,255,255,.3)), url(${props.image});
            background-size: auto;
            background-repeat: repeat;
            background-position: center top;
            background-attachment: fixed;
            background-color: black;
        `
    }
`;

const FlexBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    @media only screen and (min-width: ${breakpoints.xxsm}) {
        padding: 3rem 5%;
    }

    @media only screen and (min-width: ${breakpoints.md}) {
        padding: 5rem 5%;
    }
`;

const Logo = styled.img<{type: string}>`
    width: ${props => props.type === 'trisunca' ? '200px' : '170px'};
    height: ${props => props.type === 'trisunca' ? '28px' : '142px'};

    ${(props) =>
        props.type === 'trisunca' && `
          padding-top: 20px;

          @media only screen and (min-width: ${breakpoints.xxxl}) {
            padding-top: 55px;
        }
      `}

    @media only screen and (min-width: ${breakpoints.md}) {
        width: ${props => props.type === 'trisunca' ? '400px' : '170px'};
        height: ${props => props.type === 'trisunca' ? '57px' : '142px'};
    }
`;

const Description = styled.p<{type: string}>`
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 20px;
    font-weight: ${props => props.type === 'quet' ? '400' : '800'};
    color: ${props => props.type === 'quet' ? 'black' : 'white'};
    text-align: left;
`;

export type SingleWine = {
    id: number;
    image: string;
    name: string | JSX.Element;
    description: string | JSX.Element;
    liter: string | JSX.Element;
    //for QR code redirection
    urlName: string;
    subtitle?: string | JSX.Element;
    //for dynamic title and descrption
    metaTitleId?: string;
    metaDescriptionId?: string;
}

type WineListPageProps = {
    backgroundImage: string;
    wineLogo: string;
    descriptionText: string;
    wines: SingleWine[];
    type: 'quet' | 'fruskac' | 'trisunca';

}
const WineListPage = ({backgroundImage, wineLogo, descriptionText, wines, type}: WineListPageProps) => {

    return (
        <BackgroundImage image={backgroundImage} type={type}>
            <FlexBox>
                <Logo src={wineLogo} alt='logo' type={type} />
                <Description type={type} id='description-text'>
                    {descriptionText}
                </Description>
                <WineList wines={wines} type={type}/>
            </FlexBox>
        </BackgroundImage>
    );
}

export default WineListPage;